import React, { useState } from "react"
import ViewStory from "../../components/media-output/view-story/view-story"
import ReactTooltip from "react-tooltip"

const CreateStory = ({ location }) => {
  console.log("current location: ", location)
  const [item, setItem] = useState({})

  return (
    <>
      <ViewStory item={item} location={location} isCreate={true} />
      <ReactTooltip
        place="bottom"
        type="info"
        effect="solid"
        globalEventOff="click"
      />
    </>
  )
}

export default CreateStory
